import EN from './en'

const DE = {
  en: 'English',
  de: 'Deutsch',
  it: 'Italiano',
  fr: 'Français',
  es: 'Español',
  language: 'Deutsch',
  help: 'Hilfe',
  newHere: 'Neu hier? ',
  createAccount: 'Account erstellen',
  forgotPassword: 'Passwort vergessen?',
  rememberMe: 'Angemeldet bleiben',
  siroBlue: 'Siroforce Mobile',
  signIn: 'EINLOGGEN',
  faq: 'FAQ/Feedback/Support',
  mobileOverview: 'Siroforce Mobile im Überblick',
  siroforceMobile: 'Zugang zu Siroforce Mobile',
  deviceRegistration: 'Geräteregistrierung',
  ticketing: 'Servicefall / Ticket',
  preventiveMaintenance: 'Präventive Wartung / Market Action',
  marketAction: 'Market Action',
  monitoring: 'Auswertung und Reporting',
  helpcenter: 'Hilfezentrum',
  welcome: 'Willkommen bei Siroforce Mobile.',
  access: 'Ihr Zugriff auf den technischen Support von Dentsply Sirona.',
  email: 'E-Mail Adresse',
  password: 'Passwort',
  rightsReserved: '© 2024 Dentsply Sirona. Alle Rechte vorbehalten.',
  privacy: 'Datenschutzerklärung',
  termsOfUse: 'Nutzungsbedingungen',
  imprint: 'Impressum',
  commercialArea: 'Händlerbereich',
  compliance: 'Compliance & Ethikrichtlinien',
  inc: 'Dentsply Sirona Inc',
  instructions: 'Instructions for Use',
  register: 'Registrieren',
  emailFieldLabel: 'E-Mail (Login)',
  provideValidEmail: 'Bitte eine gültige E-Mail Adresse angeben',
  provideValidPassword: 'Bitte richtiges Passwortformat beachten',
  passwordsDoNotMatch: 'Passwörter stimmen nicht überein',
  fieldRequired: 'Bitte Pflichtfeld ausfüllen',
  firstName: 'Vorname',
  lastName: 'Nachname',
  phone: 'Telefonnummer',
  noPartnersFound: 'Keine Fachhändler gefunden',
  zipCode: 'PLZ',
  chooseState: 'Bundesstaat',
  requiredPartner: 'Ein Händler muss ausgewählt sein',
  myRole: 'Meine Rolle',
  technician: 'Servicetechniker',
  sales: 'Vertrieb',
  chooseCountry: 'Bitte wählen Sie Ihr Land',
  passwordRepeat: 'Passwort wiederholen',
  emailTaken: 'E-Mail bereits registriert',
  signUp: 'Registrieren',
  error: 'Fehler',
  registeredInClassic:
    'Sie sind bereits für Siroforce Mobile registriert. Bitte loggen Sie sich ein',
  registeredInEKP:
    'Sie sind bereits für EKP registriert. Bitte loggen Sie sich ein',
  close: 'Schließen',
  confirm: 'Bestätigen',
  none: 'Leer',
  registrationSuccess:
    'Danke für ihre Registrierung. Sollten Sie keine Bestätigungs-E-Mail erhalten, überprüfen Sie bitte Ihren Spam-Ordner. Erst wenn Ihre E-Mail Adresse validiert wurde, kann die Registrierung abgeschlossen werden.',
  registrationReceived: 'Registrierungsanfrage erhalten',
  registrationFailed:
    'Registrierung fehlgeschlagen. Versuchen Sie später erneut.',
  youAre: 'Sie sind?',
  endCustomer: 'Endkunde',
  technicianSales: 'Techniker / Handel',
  thirdParty: '3rd Party / Dienstleister',
  check: 'Abschicken',
  alreadyRegisteredEKPStart:
    'Es ist bereits ein Benutzer mit Ihrer E-Mail Adresse vorhanden',
  here: 'hier',
  notRegisteredInEKP:
    'Es ist noch kein Benutzer vorhanden. Bitte registrieren Sie sich.',
  alreadyExistingInTechnicianButEKP:
    'Für Ihre E-Mail Adresse existiert bereits ein Account im Technikerportal.',
  wantToTransferToEKP:
    'Möchten Sie jedoch Ihren Account in das Customer Complaint Portal umziehen lassen, wenden Sie sich bitte per E-Mail an %s',
  alreadyRegisteredInClassic:
    'Es ist bereits ein Benutzer mit Ihrer E-Mail Adresse vorhanden',
  notRegisteredInClassic:
    'Es ist noch kein Benutzer vorhanden. Bitte registrieren Sie sich.',
  alreadyExistingInEKPButTechnician:
    'Für Ihre E-Mail Adresse existiert bereits ein Account im Customer Complaint Portal.',
  wantToTransferToClassic:
    'Möchten Sie jedoch Ihren Account nach Technikerportal umziehen lassen, wenden Sie sich bitte per E-Mail an %s',
  confirmHeading: 'Vielen Dank für die Bestätigung Ihrer E-Mail Adresse.',
  confirm1Body:
    'Dentsply Sirona Fachhändler erhalten in der Regel innerhalb weniger Minuten eine E-Mail mit der durchgeführten Freischaltung für Siroforce Mobile. Sofern Sie kurzfristig keine E-Mail bekommen, muss Ihre Registrierung von Dentsply Sirona überprüft werden. Wir melden uns in der Regel innerhalb von 1-2 Werktagen bei Ihnen.',
  confirm2Body:
    'Ihr Konto wurde aktiviert. Sie können sich jetzt direkt bei Siroforce Mobile anmelden.',
  loginSiroforce: 'Login Siroforce Mobile',
  successfullLogin: 'Erfolgreich eingeloggt!',
  errorLoggingIn: 'Fehler beim einloggen.',

  provideValidQueryParams: 'Provide valid query parameters.',
  verifyAssetInformation: 'Bestätigen Ihrer Geräteinformation',
  assetFound:
    'Bitte bestätigen Sie die nachfolgende Geräteinformation in Form von Serial- und Referenznummer. Sie finden diese Information auf dem Typenschild, welches sich auf der Rückseite Ihres Gerätes befindet.',
  verifyAssetFromLabel: 'Please verify the asset information from the label',
  materialNumber: 'REF / Model-No.',
  serialNumber: 'SN / Serial-No.',
  pleaseConfirm: 'Bitte bestätigen',
  false: 'Ändern',
  unexpectedError: 'Unerwarteter Fehler. Bitte versuchen Sie es erneut.',
  invalidToken:
    'Error. Check whether your token is valid and not expired and try again.',
  assetNotFound: 'Could not find your equipment. Please select manually.',
  yourDevice: 'Eingabe Ihrer Geräteinformation',
  enterMaterialAndSerial:
    'Bitte geben Sie Ihre Geräteinformation in Form von Serial- und Referenznummer ein. Sie finden diese Information auf dem Typenschild, welches sich auf der Rückseite Ihres Gerätes befindet.',
  next: 'Weiter',
  enterPostalCode: 'Eingabe Ihrer Postleitzahl',
  authorizeByEnteringPostalCode:
    'Wir haben eine bestehende Registrierung zu Ihrem Gerät gefunden. Bitte authentifizieren Sie sich mittels der Postleitzahl Ihrer Praxis, um die Richtigkeit der bestehenden Registrierung zu überprüfen.',
  postalCode: 'Postleitzahl',
  customerNotFound: 'Could not find customer. Please select manually.',
  checkContactInformation: 'Bestätigen Ihrer Registrierung',
  checkRelatedMasterData:
    'Bitte bestätigen Sie die bestehende Registrierung des Gerätes mit Dentsply Sirona oder nehmen Sie eine Änderung im Fehlerfall vor.',
  correction: 'Ändern',
  customerData: 'Ihre Praxis',
  enterCustomerData:
    'Es scheint als sei Ihr Gerät noch nicht bei Dentsply Sirona registriert. Um die Registrierung vorzunehmen, füllen Sie bitte nachfolgendes Formular mit Ihren Praxisinformationen aus.',
  name: 'Praxisname',
  street: 'Straße',
  city: 'Stadt',
  houseNumber: 'Nummer',
  country: 'Land',
  state: 'Bundesstaat',
  allowAddressOptimization: 'Dürfen wir Ihre Adressdaten optimieren?',
  reject: 'Nein',
  sent: 'Ihre Eingabe:',
  suggested: 'Unser Vorschlag:',
  selectDealer: 'Ihr Fachhandelspartner',
  selectDealerWhichMaintainsDevice:
    'Bitte wählen Sie jetzt Ihren Fachhandelspartner aus, der für den technischen Service Ihres Gerätes zuständig ist.',
  foundTopEquipment: 'Successfully identified your equipment.',
  credentialsWentWrong:
    'Something went wrong with your authentication. Please use your url with token to gain access.',
  submitSuccess:
    'Vielen Dank! Ihre Registrierung ist erfolgreich abgeschlossen.',
  confirmDealer: 'Bestätigen Ihres Fachhandelspartners',
  checkRelatedDealer:
    'Bitte bestätigen Sie die Richtigkeit Ihres Fachhandelspartners oder nehmen Sie eine Änderung im Fehlerfall vor.',
  identifyDevice: 'Identifizierung Ihres Gerätetyps',
  pleasePickDevice:
    'Wir benötigen zusätzliche Informationen zu Ihrem Gerät für die erfolgreiche Registrierung. Bitte wählen Sie den zutreffenden Gerätetyp aus, welcher auf Ihr Gerät zutrifft.',
  select: 'Auswählen',
  deviceHasToBeSelected: 'A device should be selected!',
  dealerUnknown: 'Fachhändler unbekannt',
  addMissingDealer: 'Add missing dealer',
  wrongPhoneFormat: 'Ungültiges Format Telefon',
  wrongZipFormat: 'Ungültiges Format PLZ',
  welcomeToDentsply: 'Willkommen bei Dentsply Sirona!',
  welcomeImageText:
    'Gemeinsam mit Ihnen entwickeln wir integrierte, intelligente, kundenorientierte Lösungen und Services  für verbesserte Behandlungsergebnisse und ein gesundes Lächeln. Bitte vervollständigen Sie ihre Geräteregistrierung in den nachfolgenden Schritten, sodass wir Sie zukünftig bestmöglich unterstützen können.',
  cerecPrimeScan: 'CEREC Primescan',
  cerecOmniScan: 'CEREC Omnicam',
  clinic: 'Praxis',
  serviceCenter: 'Fachhandelspartner',
  deviceSuccessfullyConnected:
    'Ihr Gerät ist jetzt erfolgreich mit der Cloud verbunden und hilft uns dabei, Ihre Kundenerfahrung mit Dentsply Sirona kontinuierlich zu verbessern.',
  deviceSuccessfullyConnected2:
    'Sie können die Sitzung jetzt beenden, indem sie das Browserfenster schließen.',
  continue: 'Weiter',
  authorize: 'Authorisieren',
  makeCorrections:
    'Bitte korrigieren Sie die fehlerhafte Informationen zu Ihrer Praxis, indem Sie zur Korrektur in die jeweilige Zeile klicken.',
  postalCodeFail:
    'Die Authorisierung mittels Postleitzahl ist leider fehlgeschlagen. Gerne korrigieren wir Ihre Informationen indem Sie uns nachfolgend Ihre Praxisinformation bereitstellen.',
  oops: 'Hoppla!',
  somethingWentWrong: 'Da ist etwas schiefgelaufen.',
  sessionTimedOut:
    'Leider ist die Gültigkeit Ihrer Sitzung abgelaufen. Bitte starten Sie die Durchführung der Registrierung neu, indem Sie in der Dentsply Sirona System Monitor Applikation auf den Link "Online-Überprüfung der Geräteregisterung" klicken. Sie können dieses Fenster jetzt schließen um automatisch zum System Monitor zu gelangen.',
  yes: 'Ja',
  welcomeDentsplyProductService:
    'Willkommen beim Dentsply Sirona Produktservice',
  authorizedTechnicianSales:
    'Autorisierter Dentsply Sirona Fachhändler <br> Dentsply Sirona Mitarbeiter',
  egDentist: '(z. B. Zahnarzt, Labor, Klinik)',
  egITService: '(z. B. IT-Dienstleister)',
  yourEmailAddress: 'Ihre E-Mail Adresse:',
  redirectedToTechnicianPortal:
    'Sie werden nun zum Technikerportal weitergeleitet...',
  registerHere: "Hier geht's zur Registrierung",
  redirectedToCustomerPortal:
    'Sie werden nun zum Customer Complaint Portal weitergeleitet...',
  asThirdPartyPleaseCall:
    'Als Drittanbieter wenden Sie sich bitte telefonisch an uns.',
  accessToTheDentsply:
    'Ein Zugang zu den Dentsply Sirona Serviceportalen ist ausschließlich Endkunden, autorisierten Fachhändlern und Dentsply Sirona Mitarbeitern vorbehalten.',
  pleaseContact:
    'Bitte wenden Sie sich mit ihrem Anliegen an den zuständigen Dentsply Sirona Fachhändler oder kontaktieren Sie telefonisch den Dentsply Sirona Produktservice.',
  pleaseCallUs: 'Rufen Sie uns an.',
  findPhoneNumbers: 'Unsere Telefonnummern finden Sie hier: ',
  iAm: 'Ich bin...',
  loginTechnicianPortal: 'Login für Techniker/DS Mitarbeiter',
  loginComplaintPortal: 'Login für Endkunde',
  notOfficialDealerDomain:
    'Ihre E-Mail-Domain ist aktuell nicht als offizielle Händler-Domain registriert. Die Überprüfung Ihrer Registrierungsanfrage muss manuell erfolgen. Bitte haben Sie Verständnis dafür, dass die Bearbeitung einige Tage in Anspruch nehmen kann.',
  open: 'Anmeldung',
  filter: 'Filter (Name, Stadt, PLZ)',
  technicianPortal: 'Technikerportal',
  confirmErrorBody: 'E-Mail Verifikation fehlgeschlagen.',
  maintenanceHeading: 'Wartungsmodus',
  maintenanceBody:
    'Es finden gerade Wartungsarbeiten für Siroforce statt. Wir sind bald wieder zurück.',
  feedbackHeader:
    'Danke, dass Sie sich die Zeit nehmen uns Ihr Feedback zu geben. Dies wird uns helfen, diese Website für alle zu verbessern.',
  feedBackHint:
    'Dieses Feedback-Formular dient dazu, Fehler oder fehlende Elemente/Themen auf dieser Support-Website zu melden. Das Web-Team kann keinen technischen Service anbieten oder technische Anfragen an ein Serviceteam in einem weit entfernten Land weiterleiten. Bitte wenden Sie sich bei Fragen zu Produkten an Ihren örtlichen Händler oder Ihr Service-Team.',
  feedBackThankYou: 'Vielen Dank für Ihr Verständnis!',
  feedbackTitle: 'Feedback',
  feedbackName: 'Name',
  feedbackSubmit: 'Senden',
  feedbackEmail: 'E-Mail',
  feedbackDetails: 'Ihr Feedback',
  feedbackSubmitSuccess: 'Vielen Dank für Ihre Rückmeldung!',
  feedbackSubmitError:
    'Feedback kann nicht gesendet werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
  urlCopiedToClipboard: 'URL wurde in die Zwischenablage kopiert.',
  cancel: 'Abbrechen',
  createDate: 'Erstelldatum',
  customer: 'Kunde',
  description: 'Beschreibung',
  device: 'Gerät',
  dropFilesHere: 'Dateien hier ablegen',
  dropFilesOrSelect: 'Dateien hier ablegen oder auswählen',
  fileTooLarge: 'Datei ist zu groß. Maximale Dateigröße 5 MB',
  invalidMaterialNumber: 'Ungültige Materialnummer',
  localRequestStatusAll: 'Alle',
  localRequestStatusClosed: 'Geschlossen',
  localRequestStatusInProgress: 'In Bearbeitung',
  localRequestStatusOpen: 'Offen',
  localRequestStatusPending: 'Ausstehend',
  messageSendError: 'Nachricht konnte nicht gesendet werden',
  noteSendError: 'Nachricht konnte nicht gesendet werden',
  only5FilesAllowed: 'Es sind nur 5 Dateien erlaubt',
  pleaseTypeYourMessage: 'Bitte geben Sie Ihre Nachricht ein',
  request: 'Service Anfrage',
  requestStatusAdditionProvided: 'In Bearbeitung',
  requestStatusCancelled: 'Abgebrochen',
  requestStatusCompleted: 'Abgeschlossen',
  requestStatusCustomerAction: 'Warten auf Ihre Antwort',
  requestStatusForwarded: 'In Bearbeitung',
  requestStatusInProgress: 'In Bearbeitung',
  requestStatusSolutionProvided: 'Lösung bereitgestellt',
  selectRole: 'Rolle auswählen',
  status: 'Status',
  uploadAttachments: 'Anhänge hochladen',
  PleaseContactYourSupportTeam:
    'Bitte kontaktieren Sie unseren Applikationssupport',
  ErrorCode: 'Fehler code: ',
  pushNotification: '"Push" Nachrichten',
  repairOrder: 'Reparatur Anfrage',
  INIT: 'Erstellt',
  AWDE: 'Warten auf Gerät',
  REPA: 'Repariert',
  CANC: 'Storniert',
  INSP: 'Inspektion',
  AWCA: 'Wartern auf Freigabe',
  REST: 'Reparatur gestartet',
  REDE: 'Reparatur abgelehnt',
  CLOS: 'Abgeschlossen',
  REAP: 'Reparatur genehmigt',
  REPE: 'Warten auf Freigabe',
  DERE: 'Gerät empfangen',
  approveRepair: 'Reparatur genehmigen',
  approveRepairDialogText:
    'Sie haben sich für eine Reparatur entschieden.\n\nBitte bestätigen Sie Ihre Entscheidung.',
  rejectRepairAndReturnDevice: 'Reparatur ablehnen & Gerät zurück senden',
  rejectRepairAndReturnDeviceDialogText:
    'Sie haben den Kostenvoranschlag abgelehnt und sich für eine Rücksendung des Gerätes entschieden.\n\nBitte beachten Sie, dass hiermit weitere Kosten für den Rückversand entstehen können.\n\nBitte bestätigen Sie Ihre Entscheidung.',
  rejectRepairAndScrapDevice: 'Reparatur ablehnen & Gerät verschrotten',
  rejectRepairAndScrapDeviceDialogText:
    'Sie haben den Kostenvoranschlag abgelehnt und sich für eine Verschrottung des Gerätes entschieden.\n\nBitte beachten Sie, dass hiermit weitere Kosten für die Verschrottung entstehen können.\n\nBitte bestätigen Sie Ihre Entscheidung.',
  SL: 'Sehr geehrte Damen und Herren,\n\nanbei erhalten Sie den Begleitschein für den Versand Ihres Gerätes. Bitte verpacken Sie das Gerät und legen den Begleitschein in das Paket. Die Versandmarke wird durch den Frachtdienstleister am Tag der Abholung zur Verfügung gestellt.\n\nBitte beachten Sie, dass Ihre eingesendete Verpackung aufgrund von Hygienebestimmungen entsorgt werden muss.\n\nSollten Sie noch Fragen haben, so nutzen Sie gern die Nachrichtenfunktion.\n\nMit freundlichen Grüßen,\nIhr Dentsply Sirona Repair Center',
  KV: 'Sehr geehrte Damen und Herren,\n\nwir haben Ihr Gerät zur Reparatur erhalten. Anbei erhalten Sie den Kostenvoranschlag.\n\nBitte geben Sie den Kostenvoranschlag frei.\n\nHierzu betätigen Sie einfach den unten aufgeführten Button. Für Rückfragen stehen wir Ihnen zur Verfügung. Sie können hierzu gern die Chat-Funktion nutzen.\n\nMit freundlichen Grüßen,\nIhr Dentsply Sirona Repair Center',
  RR: 'Sehr geehrte Damen und Herren,\n\nwir haben die Reparatur Ihres Gerätes durchgeführt und möchten Ihnen hierzu unseren Arbeitsbericht zukommen lassen.\n\nFalls Sie noch Fragen haben, können Sie uns auch gerne eine Nachricht zusenden.\n\nMit freundlichen Grüßen,\nIhr Dentsply Sirona Repair Center',
  DR: 'Sehr geehrte Damen und Herren,\n\nwir haben Ihr Gerät zur Reparatur erhalten. Anbei erhalten Sie den Diagnosebericht.\n\nMit freundlichen Grüßen,\nIhr Dentsply Sirona Repair Center',
  repairApproved: 'Die Reparatur wurde genehmigt.',
  repairRejectedAndReturnDevice:
    'Die Reparatur ist abgelehnt. Bitte um Rücksendung des Geräts.',
  repairRejectedAndScrapDevice:
    'Die Reparatur ist abgelehnt. Bitte um Verschrottung des Geräts.',
  setupPasswordText:
    'Legen Sie bitte ein Passwort fest, um die bereits registrierten Dentsply Sirona Produkte Ihrer Praxis anzuzeigen bzw. weitere Geräte zu registrieren. Außerdem können Sie jederzeit Ihre Serviceanfragen an den Dentsply Sirona Produktservice senden bzw. frühere Anfragen einsehen.',
  setupPassword: 'Passwort festlegen'
}

const withDefaults = { ...EN, ...DE }

export default withDefaults
