import { createEnv } from '@t3-oss/env-nextjs'
import { z } from 'zod'

export const env = createEnv({
  /*
   * Serverside Environment variables, not available on the client.
   * Will throw if you access these variables on the client.
   */
  server: {
    GOOGLE_API_KEY: z.string(),
    EM_SECRET: z.string(),
    ZENDESK_API_TOKEN: z.string(),
    ZENDESK_USERNAME: z.string(),
    ZENDESK_REMOTE_URI: z.string(),
  },
  /*
   * Environment variables available on the client (and server).
   *
   * 💡 You'll get type errors if these are not prefixed with NEXT_PUBLIC_.
   */
  client: {
    NEXT_PUBLIC_BASE_PATH: z.string(),
    NEXT_PUBLIC_HOST_URL: z.string(),
    NEXT_PUBLIC_API_URL: z.string(),
    NEXT_PUBLIC_SIMO_BASE_URL: z.string(),
    NEXT_PUBLIC_SIMO_API_URL: z.string(),
    NEXT_PUBLIC_EKP_API_URL: z.string(),
    NEXT_PUBLIC_SABIO_BASE_URL: z.string(),
    NEXT_PUBLIC_CONTENT_VIEW_ID_EN: z.string(),
    NEXT_PUBLIC_CONTENT_VIEW_ID_DE: z.string(),
    NEXT_PUBLIC_CONTENT_VIEW_ID_IT: z.string(),
    NEXT_PUBLIC_CONTENT_VIEW_ID_FR: z.string(),
    NEXT_PUBLIC_CONTENT_VIEW_ID_ES: z.string(),
  },
  /*
   * Due to how Next.js bundles environment variables on Edge and Client,
   * we need to manually destructure them to make sure all are included in bundle.
   *
   * 💡 You'll get type errors if not all variables from `server` & `client` are included here.
   */
  runtimeEnv: {
    GOOGLE_API_KEY: process.env.GOOGLE_API_KEY,
    EM_SECRET: process.env.EM_SECRET,
    ZENDESK_API_TOKEN: process.env.ZENDESK_API_TOKEN,
    ZENDESK_USERNAME: process.env.ZENDESK_USERNAME,
    ZENDESK_REMOTE_URI: process.env.ZENDESK_REMOTE_URI,
    NEXT_PUBLIC_BASE_PATH: process.env.NEXT_PUBLIC_BASE_PATH,
    NEXT_PUBLIC_HOST_URL: process.env.NEXT_PUBLIC_HOST_URL,
    NEXT_PUBLIC_API_URL: process.env.NEXT_PUBLIC_API_URL,
    NEXT_PUBLIC_SIMO_BASE_URL: process.env.NEXT_PUBLIC_SIMO_BASE_URL,
    NEXT_PUBLIC_SIMO_API_URL: process.env.NEXT_PUBLIC_SIMO_API_URL,
    NEXT_PUBLIC_EKP_API_URL: process.env.NEXT_PUBLIC_EKP_API_URL,
    NEXT_PUBLIC_SABIO_BASE_URL: process.env.NEXT_PUBLIC_SABIO_BASE_URL,
    NEXT_PUBLIC_CONTENT_VIEW_ID_EN: process.env.NEXT_PUBLIC_CONTENT_VIEW_ID_EN,
    NEXT_PUBLIC_CONTENT_VIEW_ID_DE: process.env.NEXT_PUBLIC_CONTENT_VIEW_ID_DE,
    NEXT_PUBLIC_CONTENT_VIEW_ID_IT: process.env.NEXT_PUBLIC_CONTENT_VIEW_ID_IT,
    NEXT_PUBLIC_CONTENT_VIEW_ID_FR: process.env.NEXT_PUBLIC_CONTENT_VIEW_ID_FR,
    NEXT_PUBLIC_CONTENT_VIEW_ID_ES: process.env.NEXT_PUBLIC_CONTENT_VIEW_ID_ES,
  },
})
